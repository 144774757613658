import React, { useContext, useState } from 'react';
import {  AppBar, Toolbar, Typography, IconButton, FormControlLabel, Switch, TextField, MenuItem, Select, SelectChangeEvent, Tooltip, Box, Popover, Button, Grid, Fade, Collapse, Chip, Paper, Divider, useMediaQuery, Theme } from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { SubredditContext } from '../contexts/SubredditContext';
import { ThemeContext } from '../contexts/ThemeContext';

type NavbarProps = {
  subreddit: string[];
  setSubreddit: (subreddit: string[]) => void;
  refreshMemes: () => void;
  autoSwitch: boolean;
  setAutoSwitch: (value: boolean) => void;
  switchInterval: number;
  setSwitchInterval: (value: number) => void;
  sort: string;
  setSort: (sort: string) => void;
};

const MyNavbar: React.FC<NavbarProps> = ({ subreddit, setSubreddit, refreshMemes, autoSwitch, setAutoSwitch, switchInterval, setSwitchInterval, sort, setSort }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [subredditAnchorEl, setSubredditAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const subredditList = useContext(SubredditContext);
  const { theme, switchTheme } = useContext(ThemeContext);
  const [hovered, setHovered] = useState(false);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleSubredditChange = (event: SelectChangeEvent<string[]>) => {
    setSubreddit(event.target.value as string[]);
    refreshMemes();  // Refresh memes when the subreddit changes
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoSwitch(event.target.checked);
  };

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchInterval(Number(event.target.value));
  };

  const handleSortChange = (newSort: string) => {
    setSort(newSort);
    refreshMemes();  // Refresh memes when the sort changes
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubredditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSubredditAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubredditClose = () => {
    setSubredditAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const subredditOpen = Boolean(subredditAnchorEl);
  const subredditId = subredditOpen ? 'subreddit-popover' : undefined;
  const gridBreakpointValues = { xs: 3, sm: 2.5, md: 2.5, lg: 2.5, xl: 1.8 };

  return (
    <AppBar position="sticky" color="default">
      <Toolbar>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={3}>
            <Button startIcon={<RefreshIcon />} onClick={refreshMemes}>
              {matches ? null : 'Refresh'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={1} sx={{ padding: 0, display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center',  margin: '0 auto'}}>
              <Button startIcon={<AddBoxTwoToneIcon />} onClick={handleSubredditClick}>
                {matches ? null : (
                  <Typography variant='subtitle1' component="span" sx={{fontSize: '1rem'}}>
                    Select Subreddits
                  </Typography>
                )}
              </Button>
              <Popover
                id={subredditId}
                open={subredditOpen}
                anchorEl={subredditAnchorEl}
                onClose={handleSubredditClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Box p={1} display="flex" flexWrap="wrap" gap={1}>
                  {subredditList.map((sub) => (
                    <Chip
                      key={sub}
                      label={sub}
                      clickable
                      color={subreddit.includes(sub) ? "primary" : "default"}
                      onClick={() => {
                        if (subreddit.includes(sub)) {
                          setSubreddit(subreddit.filter(s => s !== sub));
                        } else {
                          setSubreddit([...subreddit, sub]);
                        }
                        refreshMemes();
                      }}
                    />
                  ))}
                </Box>
              </Popover>

              <Grid container justifyContent="flex-end" alignItems="center">
  <Grid item {...gridBreakpointValues} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Button
      startIcon={
        <LocalFireDepartmentTwoToneIcon
          color={sort === 'hot' ? 'error' : 'inherit'}
        />
      }
      onClick={() => handleSortChange('hot')}
    >
      {matches ? null : 'Hot'}
    </Button>
  </Grid>

  <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Divider orientation="vertical" flexItem />
  </Grid>

  <Grid item {...gridBreakpointValues} sx={{ display: { xs: 'none', sm: 'block' } }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Button
      startIcon={
        <ArrowCircleUpTwoToneIcon
          color={sort === 'top' ? 'error' : 'inherit'}
        />
      }
      onClick={() => handleSortChange('top')}
    >
      {matches ? null : 'Top'}
    </Button>
  </Grid>

  <Grid item {...gridBreakpointValues} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Button
      startIcon={<FiberNewIcon color={sort === 'new' ? 'error' : 'inherit'} />}
      onClick={() => handleSortChange('new')}
    >
      {matches ? null : 'New'}
    </Button>
  </Grid>
</Grid>
            </Paper>
          </Grid>



          <Grid item xs={3}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Tooltip title="Settings" arrow>
                <IconButton onClick={handleClick}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Box p={2}>
                  <FormControlLabel
                    control={<Switch checked={autoSwitch} onChange={handleSwitchChange} />}
                    label={matches ? null : 'Auto Switch'}
                  />
                  <TextField
                    label={matches ? null : 'Switch Interval'}
                    type="number"
                    InputProps={{
                      inputProps: { 
                        min: 5,
                        max: 60
                      }
                    }}
                    variant="standard"
                    value={switchInterval}
                    onChange={handleIntervalChange}
                  />
                </Box>
              </Popover>

              <Tooltip title={theme === 'light' ? 'Dark Mode' : 'Light Mode'} arrow>
                <IconButton onClick={switchTheme} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  {hovered ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default MyNavbar;
