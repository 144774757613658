import React from 'react';

export const subredditList = [
    '4chan',
    'AdviceAnimals',
    'AnimalsBeingBros',
    'AskReddit',
    'Aww',
    'ComedyCemetery',
    'crappyoffbrands',
    'dankmemes',
    'devilmaycry',
    'funny',
    'gifs',
    'im14andthisisdeep',
    'MadeMeSmile',
    'madlads',
    'MemeEconomy',
    'Meanjokes',
    'meme',
    'memes',
    'me_irl',
    'nobodyasked',
    'positivegifs',
    'PrequelMemes',
    'puns',
    'PewdiepieSubmissions',
    'suicidebywords',
    'terriblefacebookmemes',
    'trippinthroughtime',
    'UpliftingNews',
    'Wholesomememes',
    'wheredidthesodago',
    'WhitePeopleTwitter',
    'wholesomebpt',
    'conservativememes',
    
  ];
  
export const SubredditContext = React.createContext(subredditList);
