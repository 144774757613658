import React from 'react';

export type ThemeContextType = {
  theme: 'light' | 'dark';
  switchTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: 'light',
  switchTheme: () => {},
});
