// components/MemeService.ts
import { useState, useEffect } from 'react';

type RedditPost = {
  data: {
    url: string;
  };
};

type RedditApiResponse = {
  data: {
    children: RedditPost[];
  };
};

export const useMemeService = (subreddits: string[], sort: string) => {
  const [memes, setMemes] = useState<RedditPost[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif'];

  const corsProxyUrl = 'https://corsproxy.io/?';

  const fetchMemes = async () => {
    setIsLoading(true);
    try {
      const fetchedMemes: RedditPost[] = [];
      for (const subreddit of subreddits) {
        // Prepend the CORS proxy URL to the Reddit API endpoint
        const url = `${corsProxyUrl}${encodeURIComponent(`https://www.reddit.com/r/${subreddit}/${sort}.json?limit=100`)}`;
        const response = await fetch(url);
        const data: RedditApiResponse = await response.json();
        fetchedMemes.push(...data.data.children);
      }
      
      // Filter the memes to include only compatible image types
      const filteredMemes = fetchedMemes.filter((meme) => imageTypes.includes(meme.data.url.split('.').pop() || ''));

      // Shuffle memes array
      const shuffledMemes = shuffleArray(filteredMemes);

      setMemes(shuffledMemes);
    } catch (error: any) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to shuffle array
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    fetchMemes();
  }, [subreddits, sort]);

  return { memes, error, isLoading, fetchMemes };
};
