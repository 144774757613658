import React, { useState, useRef, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { keyframes } from '@mui/system';

type Meme = {
  data: {
    url: string;
  };
};

type MemeDisplayProps = {
  memes: Meme[];
  autoSwitch: boolean;
  switchInterval: number;
};

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const MemeDisplay: React.FC<MemeDisplayProps> = ({ memes, autoSwitch, switchInterval }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const mainImageContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSwipeLeft = () => {
    if (memes.length > 0) {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % memes.length);
    }
  };

  const handleSwipeRight = () => {
    if (memes.length > 0) {
      setSelectedIndex((prevIndex) => (prevIndex - 1 + memes.length) % memes.length);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (thumbnailContainerRef.current) {
        thumbnailContainerRef.current.scrollLeft += e.deltaY;
      }
    };

    const handleMainImageWheel = (e: WheelEvent) => {
      if (e.deltaY > 0) {
        handleSwipeLeft();
      } else {
        handleSwipeRight();
      }
    };

    const thumbnailContainer = thumbnailContainerRef.current;
    const mainImageContainer = mainImageContainerRef.current;

    if (thumbnailContainer) {
      thumbnailContainer.addEventListener('wheel', handleWheel);
    }

    if (mainImageContainer) {
      mainImageContainer.addEventListener('wheel', handleMainImageWheel);
    }

    return () => {
      if (thumbnailContainer) {
        thumbnailContainer.removeEventListener('wheel', handleWheel);
      }

      if (mainImageContainer) {
        mainImageContainer.removeEventListener('wheel', handleMainImageWheel);
      }
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (autoSwitch && memes.length > 0) {
      timer = setInterval(() => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % memes.length);
      }, switchInterval * 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [autoSwitch, switchInterval, memes.length]);

  return (
    <div {...swipeHandlers} style={{ backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff', color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}>
      {memes.length > 0 && (
        <Box ref={mainImageContainerRef} sx={{ position: 'relative', width: '100%', height: 'calc(100vh - 190px)', pt: '.5%',pb: '.5%' }}>
          <img
            src={memes[selectedIndex].data.url}
            alt="meme"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          {!isMobile && (
            <>
              <Box
                sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                onClick={handleSwipeRight}
              >
                <ArrowBackIosIcon />
              </Box>
              <Box
                sx={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                onClick={handleSwipeLeft}
              >
                <ArrowForwardIosIcon />
              </Box>
            </>
          )}
        </Box>
      )}
      <Box ref={thumbnailContainerRef} sx={{ position: 'fixed', bottom: 0, width: '100%', display: 'flex', overflowX: 'auto', gap: 2, p: 1, height: 120, '&::-webkit-scrollbar': { width: '0.4em', height: '0.4em' }, '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)', borderRadius: '4px' }, '&::-webkit-scrollbar-thumb': { borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}>
        {memes.map((meme, index) => (
          <Box
            key={meme.data.url}
            onClick={() => setSelectedIndex(index)}
            sx={{ 
              flex: '0 0 auto', 
              height: '100%', 
              cursor: 'pointer', 
              border: index === selectedIndex ? '2px solid #3f51b5' : 'none',
              animation: `${slideInFromRight} 0.5s` 
            }}
          >
            <img src={meme.data.url} alt="meme" style={{ width: 'auto', height: '100%', display: 'block' }} />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default MemeDisplay;
