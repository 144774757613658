import React, { useState, useCallback, useEffect } from 'react';
import MemeDisplay from './components/MemeDisplay';
import MyNavbar from './components/Navbar';
import { useMemeService } from './services/MemeService';
import { SubredditContext, subredditList } from './contexts/SubredditContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { MyThemeProvider } from './contexts/MyThemeProvider'; // Import the MyThemeProvider

const App: React.FC = () => {
  // Load subreddits from local storage or select a random one
  const initialSubreddits = JSON.parse(localStorage.getItem('subreddits') ?? JSON.stringify([subredditList[Math.floor(Math.random() * subredditList.length)]]));
  const [subreddits, setSubreddits] = useState(initialSubreddits);
  const [sort, setSort] = useState('hot');
  const [autoSwitch, setAutoSwitch] = useState(true);
  const [switchInterval, setSwitchInterval] = useState(30);
  const { memes, error, isLoading, fetchMemes } = useMemeService(subreddits, sort);

  const [open, setOpen] = useState(false);

  const refreshMemes = useCallback(() => {
    fetchMemes();
  }, [fetchMemes]);

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);
  
  // Save subreddits to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('subreddits', JSON.stringify(subreddits));
  }, [subreddits]);
  
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };
  
  return (
    <MyThemeProvider> 
      <SubredditContext.Provider value={subredditList}>
        <div>
          <MyNavbar
            subreddit={subreddits}
            setSubreddit={setSubreddits}
            refreshMemes={refreshMemes}
            autoSwitch={autoSwitch}
            setAutoSwitch={setAutoSwitch}
            switchInterval={switchInterval}
            setSwitchInterval={setSwitchInterval}
            sort={sort}
            setSort={setSort}
          />
          {isLoading && <LinearProgress color="secondary" />}
          <MemeDisplay memes={memes} autoSwitch={autoSwitch} switchInterval={switchInterval} />
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {error?.message}
            </Alert>
          </Snackbar>
        </div>
      </SubredditContext.Provider>
    </MyThemeProvider>
  );
};

export default App;
